export const GQL_FETCH_MSKED_PAGE_BANNER_ENTRY = `
    mskedHeroBanner: pageComponentsCollection(where: { sys: { id: "6xMststrs7vwIUiYdGHEMc" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_MSKED_PAGE_EDUCATION_DELIVERED_ENTRY = `
    educationDelivered: pageComponentsCollection(
      where: {sys: {id: "5IyOcfbU0PbWaDLf1g4Weq"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MSKED_PAGE_EASY_TO_UNDERSTAND_ENTRY = `
    easyToUnderstand: pageComponentsCollection(
      where: {sys: {id: "67KeuusR3QrlGB1oyhZgvi"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MSKED_PAGE_130_TOPIC_ENTRY = `
    topic: pageComponentsCollection(
      where: {sys: {id: "2fKEGjdJoTcfpN9gunVCAR"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MSKED_PAGE_PRESCRIBE_EDUCATIONAL_ENTRY = `
    prescribeEducational: pageComponentsCollection(
      where: {sys: {id: "4fHfgZIn06aQB5CWYD0heZ"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MSKED_PAGE_VALD_SYSTEMS_ENTRY = `
    mskedRelatedSystems: pageComponentsCollection(
      where: {sys: {id: "iZPK6hniahwg6zp6WYm0N"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
        }
      }
    }
`;

export const GQL_FETCH_MSKED_PAGE_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "4lvl1vRigo893LOrjldlFG"}}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_MSKED_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "2BtvekegpuIyIpMNtRKy4M" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
