'use client';

import React from 'react';
import debounce from 'lodash.debounce';
import { changeWindowDimensionAXN } from '@src/redux/actions/windowDimensionActions/windowDimensionActions';
import { useTypedDispatch } from '@src/redux/store/store.types';
const App: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const dispatch = useTypedDispatch();
  const handleResize = React.useCallback(() => {
    // Convert window height to 'vh' (viewport height)
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    dispatch(changeWindowDimensionAXN({
      height: window.innerHeight,
      width: window.innerWidth
    }));
  }, [dispatch]);

  // Debounce the resize handler
  const debouncedHandleResize = React.useCallback(debounce(handleResize, 250), [handleResize]);
  React.useEffect(() => {
    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Set up the event listener for window resize
    window.addEventListener('resize', debouncedHandleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize, handleResize]);
  return <>{children}</>;
};
export default App;