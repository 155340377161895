export const GQL_FETCH_SCOREIT_PAGE_HERO_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "6RKx5nlUzJHQBRctAgecKl"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_SCOREIT_PAGE_DIGITAL_PROMS_ENTRY = `
  digitalProms: pageComponentsCollection(where: {sys: {id: "7DeR49SpWRrVltQnlIzTSQ"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SCOREIT_PAGE_CONVENIENT_ENTRY = `
  convenient: pageComponentsCollection(where: {sys: {id: "1cvt0SddDftJk9byndlNWO"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_SCOREIT_PAGE_VALIDATED_ENTRY = `
  validated: pageComponentsCollection(where: {sys: {id: "6Z4A3mrMGWtsNi5X0ofqet"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_SCOREIT_PAGE_DELIVERED_ENTRY = `
  delivered: pageComponentsCollection(where: {sys: {id: "2ARFayiu0ChaUHkRoeCZe1"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SCOREIT_PAGE_PROMS_LIBRARY_ENTRY = `
  promsLibrary: pageComponentsCollection(where: {sys: {id: "77wvxFS4qUwSEPQNnMigGR"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SCOREIT_PRODUCT_RELATED_SYSTEMS_ENTRY = `
 relatedProducts: pageComponentsCollection(where: {sys: {id: "78sGLKAG8FdgoHbhDjvTAN"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }
  }
`;

export const GQL_FETCH_SCOREIT_PAGE_RELATED_PRODUCTS_ENTRY = `
products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "4jcEWaZnvTa0c3mG8I1Fgm"}}}) {  
  items {
      sys {
      id
    }
      mediaPrimary {
      description
      url
      width
      height
    }
      logo {
      url
      description
    }
    name
    title
    slug
      bodyCopy {
      json
    }
      button {
      text
      url
      type
      target
    }
  }
}
`;

export const GQL_FETCH_SCOREIT_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "612lea1CPoNSY0abgaL5XP" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
