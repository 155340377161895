export const GQL_FETCH_SMARTSPEED_PAGE_HERO_ENTRY = `
  smartSpeedHeroBanner: pageComponentsCollection(where: {sys: {id: "3oRVpY8vdTT9WQVduWfIjE"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_SPEED_ENTRY = `
  speed: pageComponentsCollection(where: {sys: {id: "7K3w8iTRsafHpED5Jg4p1t"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_RELIABLE_ENTRY = `
  reliable: pageComponentsCollection(where: {sys: {id: "3L8Spb0ENzoFe8IrA2JMlX"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_PLUS_APP_ENTRY = `
  plusApp: pageComponentsCollection(where: {sys: {id: "2zzecqIIhr23QML5iCUehR"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_WIDE_RANGE_ENTRY = `
  wideRange: pageComponentsCollection(where: {sys: {id: "1Bhml4Yii9xK1FG6RzGfWk"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_UP_IN_LIGHTS_ENTRY = `
  inLights: pageComponentsCollection(where: {sys: {id: "2GZBioJaXncHOOvToOYF7p"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_DATA_ACCESSORIES_ENTRY = `
  accessories: pageComponentsCollection(where: {sys: {id: "2u0bhQtC5ODZ3v6KDLWpUg"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_SHOWCASE_ENTRY = `
  plusShowcase: pageComponentsCollection(where: {sys: {id: "2YE1gGFnqz4ieoNMeaQaLF"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_GRID_ENTRY = `
  smartSpeedGrid: pageComponentsCollection( where: {sys: {id: "SbqpH7deCqY3gnl1KAfv6"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        extraAssetsCollection(limit: 3) {
          items {
            ... on DuplexComponent {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_DASH_ENTRY = `
  dash: pageComponentsCollection(where: {sys: {id: "7j7bOaN9RwvpQrtDJSlNti"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
         mediaSecondary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_ADDONS_ENTRY = `
  accessoriesAddOns: pageComponentsCollection(where: { sys: { id: "3dX92uqvap0QvU7tZponkI" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_SMART_JUMP_ENTRY = `
  smartJump: pageComponentsCollection(where: { sys: { id: "iYfenkoBLPvuYxD9rbD2e" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          description
          url
          width
          height
        }
        mediaSecondary {
          description
          url
          width
          height
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_SMART_JUMP_ACCESSORIES_ENTRY = `
  smartJumpAccessories: pageComponentsCollection(where: {sys: {id: "cUjFY7WIWCZvzLCyhdnId"}}, limit: 1) {
    items {
      ... on Grid {
        sys {
          id
        }
        scrollTo
        topicCollection (limit: 3) {
          items {
            ... on Card {
              sys {
                id
              }
              mediaPrimary {
                width
                height
                url
                description
              }
              title
              bodyCopy {
                json
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_FLEXIBLE_ENTRY = `
  flexible: pageComponentsCollection(where: {sys: {id: "5XxuiDbgXtOrTk75T7LA39"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "1L9SblUZF7m0CcvDbPIPtV" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
