const isBot = (userAgent: any) => {
  // If we're on the server, we don't proceed further
  if (typeof window === 'undefined') {
    return false;
  }

  const bots = [
    'Googlebot-Image', // Google
    'Googlebot-News', // Google
    'Google-InspectionTool', // Google
    'Googlebot', // Google
    'Bingbot', // Microsoft Bing
    'Slurp', // Yahoo
    'DuckDuckBot', // DuckDuckGo
    'Baiduspider', // Baidu
    'YandexBot', // Yandex
    'MJ12bot', // Majestic
    'SEMrushBot', // SEMrush
    'AhrefsBot', // Ahrefs
    'DotBot', // Moz
    'Sogou', // Sogou
    'Exabot', // Exalead
    'facebookexternalhit', // Facebook
    'LinkedInBot', // LinkedIn
    'UptimeRobot', // Uptime Robot
    'Chrome-Lighthouse', // Google PageSpeed Insights
    'Google Page Speed Insights', // Google PageSpeed Insights
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36', // Google PageSpeed Insights
    'Mozilla/5.0 (Linux; Android 11; moto g power (2022)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Mobile Safari/537.36', // Google PageSpeed Insights
  ];

  for (const bot of bots) {
    if (userAgent.includes(bot)) {
      return true;
    }
  }

  return false;
};

export default isBot;
