export const GQL_FETCH_AIRBANDS_PAGE_HERO_ENTRY = `
  airbandsHeroBanner: pageComponentsCollection(where: { sys: { id: "5gx29fIXrJduaVJo6HfwLc" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_BFR_TRAINING_ENTRY = `
  bfrTraining: pageComponentsCollection(where: { sys: { id: "5rhZRYOAHbLUtEy3eMYSFs" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        mediaSecondary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_WHY_BFR_ENTRY = `
  whyBfr: pageComponentsCollection(where: { sys: { id: "RZdHDiRqsQfTS1BK5r1ng" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_TAKEAWAY_GUESSWORK_ENTRY = `
  takeawayGuesswork: pageComponentsCollection(where: { sys: { id: "4UCkjdlWZFlkjME5Zlf2FI" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_CONVENIENCE_ENTRY = `
  convenience: pageComponentsCollection(where: { sys: { id: "1L92KPbVBh6Ox43CMUBsV7" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_FULLY_FEATURED_ENTRY = `
  fullyFeatured: pageComponentsCollection(where: { sys: { id: "4XMHc4qmov9XMM4iTwIqF4" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        mediaSecondary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_RELATED_ENTRY = `
 related: pageComponentsCollection(where: { sys: { id: "1vlaBbOZt82ZOKcDD9KHiL" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }   
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "3rEiejykYuf0B39yVcuUTu"}}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "173JwNMPoJGYJz8EhAad9d" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
