export const GQL_FETCH_HOME_PAGE_HERO_ENTRY_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "7kZEtni0ZBbjEk1rVxJll5" } }, limit: 1) {
    items {
        ... on Banner {
        scrollTo
        title
        heroImage {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_HOME_PAGE_SITE_OPTIONS_ENTRY = `
  siteOptions: pageComponentsCollection(where: {sys: {id: "5eFqqF3LH56bYL8nt1F0og"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              slug
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
              buttonCollection(limit: 1) {
                items {
                  ... on Button {
                    url 
                    target
                    text
                    type
                  }
                }
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_HOME_PAGE_DATA_INFORMED_ENTRY = `
   dataInformed: pageComponentsCollection(where: {sys: {id: "dEkjQgOrca4i2jyczFkDq"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_HOME_PAGE_SOLUTIONS_ENTRY = `
   solutions: pageComponentsCollection(where: {sys: {id: "2txpbN9BvRELi39NKHqxba"}}, limit: 1) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          buttonCollection(limit: 3) {
            items {
              ... on Button {
                url 
                target
                text
                type
              }
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HOME_PAGE_IMAGE_GRID_ENTRY = `
   imageGrid: pageComponentsCollection(where: {sys: {id: "6oc1IXLOhLBInQq5ead3RN"}}, limit: 1) {
      items {
        ... on Grid {
          scrollTo
          topicCollection(limit: 9) {
            items {
              ... on Card {
                sys {
                  id
                }
                title
                bodyCopy {
                  json
                }
                mediaPrimary {
                  url
                  description
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HOME_MEET_OUR_CLIENT_ENTRY = `
   ourClients: pageComponentsCollection(where: {sys: {id: "6nqN4BWJjXDGerur5KVrty"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
          mediaSecondary {
            url
            description
            width
            height
          }
          extraAssetsCollection {
            items {
              ... on SectionHeader {
                title
                bodyCopy {
                  json
                }
              }
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HOME_PAGE_VALDHUB_IN_ONE_PLACE = `
  inOnePlace: pageComponentsCollection(where: {sys: {id: "4551KXTMdzKXwQS8HRdBPT"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_HOME_PAGE_WE_EXIST_ENTRY = `
  weExist: pageComponentsCollection(where: { sys: { id: "6bPoMYMMbBzAHfj2xLrHpZ" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        sys {
          id
        }
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_HOME_PAGE_CLIENT_SUCCESS_ENTRY = `
  clientSuccess: pageComponentsCollection(where: {sys: {id: "3lBKDGhcbXPtHjNsTPcYUY"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        bodyCopy {
          json
        }
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_HOME_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "1MUQeQz768wLqqN2GhBiut" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        sys {
          id
        }
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
