export const GQL_FETCH_PATHOLOGIES_PAGE_HERO_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "6VLYjG93wbP2H364tA1A6H" } }, limit: 1) {
    items {
        ... on Banner {
        scrollTo
        title
        heroImage {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_ORTHOPAEDICS_ENTRY = `
  orthopaedics: pageComponentsCollection(where: { sys: { id: "3MpUbDELLAaFviBWodY8B9" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_CONVALESCE_ENTRY = `
   convalesce: pageComponentsCollection(where: {sys: {id: "2VTZLaAhwcLl4c0SxFctUM"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_PHONE_MOCKUPS_ENTRY = `
  phoneMockup: pageComponentsCollection(where: { sys: { id: "2zQIihoA2hAHfyt1YiBMlp" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        mediaSecondary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_MSK_CONDITIONS_ENTRY = `
  mskConditions: pageComponentsCollection(where: { sys: { id: "3YpEqeLYdyENvyYm480zJl" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_CARE_ENTRY = `
  care: pageComponentsCollection(where: { sys: { id: "TUs7ac0QoLTzAr2BVAKdZ" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        mediaSecondary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_BENCHMARK_ENTRY = `
  benchmark: pageComponentsCollection(where: { sys: { id: "g0cYyuZmFXSsKOnuCKoAl" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_WE_EXIST_ENTRY = `
  weExist: pageComponentsCollection(where: { sys: { id: "5qGgb5UA87s2pw7KU7XLWX" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        sys {
          id
        }
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_CLIENT_SUCCESS = `
  clientSuccess: pageComponentsCollection(where: {sys: {id: "5tToqQv3vNFPo8D1MrbcIS"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        bodyCopy {
          json
        }
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "28u5JRV9uArMFgOtkS59wB" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        sys {
          id
        }
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
