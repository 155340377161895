export const GQL_FETCH_DYNAMO_PAGE_BANNER_TEXT_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "4q73sofwmeDaS0xGbijFeo"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_TESTS_ENTRY = `
  tests: pageComponentsCollection(where: {sys: {id: "5cWw57PZILclJ7CkmrTnW9"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_MODULARITY_ENTRY = `
  modularity: pageComponentsCollection(where: {sys: {id: "Qix574qXtkSA2V5Rq7aAj"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_ENGAGEMENT_ENTRY = `
  engagement: pageComponentsCollection(where: {sys: {id: "1gtnRUiR5Za5JrvQbA1zG6"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_USEABILITY_ENTRY = `
  useability: pageComponentsCollection(where: {sys: {id: "4mEbYMGXnzG0PHmQ1SVvGM"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_TEST_BATTERIES_ENTRY = `
  testBatteries: pageComponentsCollection(where: {sys: {id: "1DvknZWRfdTrHqmHSTPDKh"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
        }
        mediaSecondary {
          url
        }
        mediaTertiary {
          url
        }
        mediaQuaternary {
          url
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_VISIBILITY_ENTRY = `
  visibility: pageComponentsCollection(where: {sys: {id: "5ggBiEl1JOs0NEyki3L10K"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_TWO_MODELS_ENTRY = `
  twoModels: pageComponentsCollection(where: {sys: {id: "14CVI4yjTUgD1Sml5EuMjL"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }
  }
`;
export const GQL_FETCH_DYNAMO_PAGE_PLUS_ENTRY = `
  dynamoPlus: pageComponentsCollection(where: {sys: {id: "6QZcLbna1lUekCH2YCm8Vz"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            type
            url
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_LIGHT_ENTRY = `
  dynamoLight: pageComponentsCollection(where: {sys: {id: "2XVUVgPlFjb50gtpGj9f8g"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_DYNAMO_MAX_ENTRY = `
  dynamoMax: pageComponentsCollection(where: {sys: {id: "2CkcVTwVQKlAlsMO8Em0ec"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
        }
        mediaSecondary {
          url
        }
        mediaTertiary {
          url
        }
        buttonCollection {
          items {
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_FLEXIBILITY_ENTRY = `
  flexibility: pageComponentsCollection(where: {sys: {id: "3g7K3rmkK4xo0y0xWITFUC"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_RELATED_SYSTEMS_ENTRY = `
 relatedProducts: pageComponentsCollection(where: {sys: {id: "cxCNNFWaYiNxjY1zjOPE0"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }
  }
`;
export const GQL_FETCH_DYNAMO_PAGE_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "4VRUOY6v3GzhDWrLqZoB2v"}}}) {  
      items {
        sys {
        id
      }
        mediaPrimary {
        description
        url
        width
        height
      }
        logo {
        url
        description
      }
      name
      title
      slug
        bodyCopy {
        json
      }
        button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "5SJPxm3mrMF6XsnXq71MMe" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
