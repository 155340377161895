export const GQL_FETCH_TELEHAB_PAGE_HERO_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "5T3Kb5pdotE4GSVDvCZ9Fn"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_PRESCRIPTION_ENTRY = `
  prescription: pageComponentsCollection(where: {sys: {id: "5YAjFD0tHc9Z1qrCpP8xAj"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_EXERCISE_LIBRARY_ENTRY = `
  exerciseLibrary: pageComponentsCollection(where: {sys: {id: "65TXWaZSedd3eMS29CKaFe"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_PROGRAM_BUILD_ENTRY = `
  programBuilder: pageComponentsCollection(where: {sys: {id: "D70fmUUcyN2FEGKLcPUUs"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_MONITORING_ENTRY = `
  monitoring: pageComponentsCollection(where: {sys: {id: "2Kr1zcY1zWqt57ACrslBZ6"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_ENGAGEMENT_ENTRY = `
  engagement: pageComponentsCollection(where: {sys: {id: "4rmO4cSbmxCe4ZnwNnyZz"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_RELATED_SYSTEMS_ENTRY = `
 relatedProducts: pageComponentsCollection(where: {sys: {id: "6PB7n1uXreOcIUrB5uEUeL"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_RELATED_PRODUCTS_ENTRY = `
products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "cpZjkXFvDPTmrsaITF8E1"}}}) {  
  items {
      sys {
      id
    }
      mediaPrimary {
      description
      url
      width
      height
    }
      logo {
      url
      description
    }
    name
    title
    slug
      bodyCopy {
      json
    }
      button {
      text
      url
      type
      target
    }
  }
}
`;

export const GQL_FETCH_PAGE_TELEHAB_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "5dvqqoxFDtGAmdNFISAxpT" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
