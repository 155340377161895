'use client';

import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@src/redux/store/store';

/**
 * Providers component that wraps the application with ReduxProvider.
 * Add more providers here if needed.
 */
const Providers: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  return <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-component="Providers" data-sentry-source-file="Providers.tsx">{children}</ReduxProvider>;
};
export default Providers;