export const GQL_FETCH_SOLUTIONS_PAGE_HERO_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "4aEjuMNKBTIRdXvlPdzQR0" } }, limit: 1) {
    items {
        ... on Banner {
        scrollTo
        title
        heroImage {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_VALD_HUB_ENTRY = `
  valdHub: pageComponentsCollection( where: {sys: {id: "UU18xbJhuzfB5VzjZ8229"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
      }
    }
  }    
`;

export const GQL_FETCH_HOME_PAGE_DATA_INFORMED_ENTRY_ENTRY = `
   dataInformed: pageComponentsCollection(where: {sys: {id: "dEkjQgOrca4i2jyczFkDq"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_CLIENT_SUCCESS_ENTRY = `
  clientSuccess: pageComponentsCollection(where: {sys: {id: "6VtseLsyypUcDjFpUrXONk"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        bodyCopy {
          json
        }
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
              buttonCollection(limit: 1) {
                items {
                sys {
                    id
                  }
                  url
                  text
                  type
                  target
                }
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_DATA_BEYOND_ENTRY = `
   dataBeyond: pageComponentsCollection(where: {sys: {id: "3IwjdVcriOMhgLddCTtjAK"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_WE_EXIST_ENTRY = `
  weExist: pageComponentsCollection(where: { sys: { id: "6SwotYI3iyWB0UwBpYl27O" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_BETTER_MANAGEMENT_ENTRY = `
   betterManagement: pageComponentsCollection(where: {sys: {id: "6bnqofZliy3CjEYB8f53q3"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_MEASUREMENT_ENTRY = `
   measurement: pageComponentsCollection(where: {sys: {id: "oC9uONliOmLylxgWoLA9L"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_NORMATIVE_DATA_ENTRY = `
   normativeData: pageComponentsCollection(where: {sys: {id: "36tdklFGJre09Ct4D9RQXe"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
          buttonCollection(limit: 1) {
            items {
              sys {
                id
              }
              url
              text
              type
              target
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_PATIENTS_ENTRY = `
   patients: pageComponentsCollection(where: {sys: {id: "6xvnZx8THebib04ruQDSPC"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_CLINICIANS_ENTRY = `
   clinicians: pageComponentsCollection(where: {sys: {id: "1kxiQzuGCrqFNiEWtCRRY6"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_WE_EXIST_V2_ENTRY = `
  weExistV2: pageComponentsCollection(where: { sys: { id: "3COv7diySWzopPbbEJjDqO" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_LIKE_PROS_ENTRY = `
   likePros: pageComponentsCollection(where: {sys: {id: "7h42vQULAaySPy1gsV2gr9"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_TESTIMONIAL_ENTRY = `
  ourSolution: pageComponentsCollection(
    where: {sys: {id: "3uqzGevG3MQ6jkRKcvZvgC"}}
    limit: 1
  ) {
    items {
      ... on SectionHeader {
        scrollTo
        title
      }
    }
  }

  testimonial: pageComponentsCollection(
    where: {sys: {id: "15I93eZFpTonlPDtYMhGuC"}}
    limit: 1
  ) {
    items {
      ... on Testimonial {
        scrollTo
        title
        name
        company
        bodyCopy {
          json
        }
        profileImage {
          width
          height
          url
          description
        }
        logo {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_TESTIMONIALS_ENTRY = `
  testimonials: pageComponentsCollection(
    where: {sys: {id: "2n3Qami8LEL9LBBSrDAUjG"}}
    limit: 1
  ) {
    items {
      ... on Grid {
        scrollTo
        topicCollection {
          items {
            ... on Testimonial {
              sys {
                id
              }
              title
              name
              company
              bodyCopy {
                json
              }
              logo {
                url
                description
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "2yk4WDPL912Fc7ZRcKp0IT" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 3) {
          items {
            sys {
              id
            }
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
