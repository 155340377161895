export const GQL_FETCH_MOVEHEALTH_PAGE_HERO_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "5pvUrRbIbPCNvmOZlpucpC"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
        heroImageSecondary {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_TELEHAB_NOW_MOVEHEALTH = `
  nowMoveHealth: pageComponentsCollection(where: {sys: {id: "2VNChvbVl6oYK3zVKZuKJQ"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_EASY_ASSIGN = `
  easyAssign: pageComponentsCollection(where: {sys: {id: "1E4t6tfkH06mCfaY88TpHD"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_EXERCISES = `
  exercises: pageComponentsCollection(where: {sys: {id: "y0ibaWxnwwWNlEzvQudmy"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_PROGRAM_BUILD_ENTRY = `
  programBuilder: pageComponentsCollection(where: {sys: {id: "2upHmpOgVNpeFH73n7p0ec"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_QUESTIONAIRES = `
  questionaires: pageComponentsCollection(where: {sys: {id: "31gjeTYXtBYY0jmMq3YlMG"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_TOPICS = `
  topics: pageComponentsCollection(where: {sys: {id: "15QxeA25O8CFaytufHnNMT"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_EDUCATION = `
  education: pageComponentsCollection(where: {sys: {id: "4zplSBtILVaYA36xdPSguM"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_PATIENTAPP = `
  patientApp: pageComponentsCollection(where: {sys: {id: "7Mmfp9IhS6dpMiGeoHwhwh"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_RESULTS = `
  results: pageComponentsCollection(where: {sys: {id: "4CRvkrkOFMrKcIIxEgalAJ"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_PAGE_MOVEHEALTH_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "7wNZA6MeQMBWnqhB5rdDC" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
